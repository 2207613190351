<template>
    <div class="sidebartrx" style="font-size:14px">
      <div class="d-flex mt-1">
        <button class="btn w-100 py-1 text-center rounded-0" :class="[$store.state.csMenuDisplay == 'otw' ? 'btn-primary' : 'btn-outline-primary']" @click="$store.state.csMenuDisplay = 'otw'">OTW</button>
        <button class="btn w-100 py-1 text-center rounded-0" :class="[$store.state.csMenuDisplay == 'settlement' ? 'btn-primary' : 'btn-outline-primary']" @click="$store.state.csMenuDisplay = 'settlement'">DONE</button>
        <button class="btn w-100 py-1 text-center rounded-0" :class="[$store.state.csMenuDisplay == 'invalid_evidence' ? 'btn-primary' : 'btn-outline-primary']" @click="$store.state.csMenuDisplay = 'invalid_evidence'">HIDDEN</button>
      </div>
      <div>
        <button class="btn w-100 py-1 text-center rounded-0" :class="[$store.state.csMenuDisplay == 'list' ? 'btn-primary' : 'btn-outline-primary']" @click="$store.state.csMenuDisplay = 'LIST'">LIST SELESAI</button>
      </div>
      <div class="btn-outline-primary" style="text-align: center; padding: 0.5rem; font-weight: bold;cursor:pointer" @click="unreadOnly = !unreadOnly">
        <span class="d-inline-block" style="width: 11px; height: 11px; border: 1px solid grey; margin: 0 10px;border-radius: 16px;" :style="unreadOnly ? 'background:green' : ''"></span>
        <span v-if="unreadOnly">SEMUA TRANSAKSI</span>
        <span v-else>UNREAD ONLY</span>
      </div>
      <div class="caritransaksi">
        <input id="inputcari" type="text" v-model="pencarian" placeholder="Cari data..."/>
        <div id="searchIcon"><font-awesome-icon icon="search" :style="{color: 'rgba(0,0,0,0.2)'}" /></div>
      </div>
      
      <!-- transaksi list muncul disini -->
      <div v-for="tab in listTab" :key="tab.name">
        <div v-if="$store.state.csMenuDisplay == tab.name" class="listTrx overflow-auto position-relative" style="height: calc(100vh - 115px)">
          <div @click="displayTrx(trx)" @contextmenu.prevent="popUpLabeling(trx)" :class="{ 'trxactive' : trx.id == $store.state.activeTransaction.id}" class="position-relative itemTrx d-flex p-3" v-for="(trx, index) in sortList(filteredTrx)" :key="index">
            <div class='mr-3'>
              <img :src="getProviderIcon(trx.provider)" width="40px" alt="">
            </div>
            <div v-if="trx.bank != null" class="text-left">
              <div class="font-weight-bold" style="font-size:13px">{{trx.bank.atasnama}}</div>
              <div v-html="statusBadge(trx.status)"></div>
              <div class="text-small text-muted">{{fbDateToString(trx.created.seconds*1000, 'LLL')}}</div>
              <div class="hashtags">
                <div class="badge mr-1" :style="'background:'+tag.background+';color:'+tag.color" v-for="(tag, idx) in trx.hashtags" :key="'tags_'+idx">{{tag.label}}</div>
              </div>
            </div>
            <div class="position-absolute badge badge-primary" style='right:8px; top:8px; margin:8px' v-if="trx.chat.cs == 'unread'">chat</div>
          </div>
          <div style="margin-top:8rem"></div>
        </div>
      </div>

    </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import {db} from '@/main'

const statusOrder = {
    'validation': 0,
    //'pending': 1,
    'user_confirmation': 2,
    'settlement': 3,
    'invalid_evidence': 4
}

const chatRead = {
    'read': 1,
    'unread': 0
}

export default {
  data(){
    return {
      listTab: [
        {'name': 'otw'},
        {'name': 'settlement'},
        {'name': 'invalid_evidence'}
      ],
      pencarian: '',
      unreadOnly: false
    }
  },
  props: {
    realtimeTrxs: Array
  },
  methods: {
    statusBadge(status){
      switch (status) {
        case 'pending': return `<div class="badge badge-primary">${status}</div>`
        case 'user_confirmation': return `<div class="badge badge-secondary">${status}</div>`
        case 'validation': case 'settlement': return `<div class="badge badge-success">${status}</div>`
        case 'invalid_evidence': return `<div class="badge badge-danger">${status}</div>`
        default: return `<div class="badge badge-secondary">${status}</div>`
      }
    },
    displayTrx: function(trx){
      if(trx.admin == null) this.setAdmin(trx.id)
      this.$store.state.activeTransaction = trx
      this.editing = false

      if(trx.chat != null) if(trx.chat.cs == 'unread') db.collection('converts').doc(trx.id).update({'chat.cs': 'read'})
      
      var refC = firebase.database().ref('chatrooms/'+trx.id)

      if(trx.admin == null){
        refC.update({
          'admin': this.user.cs
        }).then(() => {
          this.$store.state.activeTransaction.admin = this.user.cs
        })
      }
    },
    async setAdmin(id){
      await db.collection('converts').doc(id).update({
        admin: this.user.cs
      })
      console.log('updated')
    },
    popUpLabeling(trx){
      this.$store.state.badge.trx = trx
      this.$store.state.mousePosition = {x: event.clientX, y: event.clientY}
      this.$store.state.hashtag = true
    },
    sortList(data){
        return data.sort(function (a, b) {
            return chatRead[a.chat.cs] - chatRead[b.chat.cs] || statusOrder[a.status] - statusOrder[b.status] || b.updated_at - a.updated_at;
        });
    }
  },
  computed: {
    filteredTrx: function(){
      switch (this.$store.state.csMenuDisplay) {
        case 'otw':
          return this.$props.realtimeTrxs.filter(trx => {
            if(this.unreadOnly){
              if(trx.chat != null){
                return trx.chat.cs == 'unread'
              }
            } else {
              var isProgress = false
              if(trx.status == 'pending' || trx.status == 'user_confirmation' || trx.status == 'validation') isProgress = true
              if(trx.expired == true) isProgress = false

              return isProgress && trx.bank.atasnama.toLowerCase().includes(this.pencarian.toLowerCase()) || isProgress && trx.nomor.includes(this.pencarian)
            ;
            }
          })
        
        case 'settlement':
          return this.$props.realtimeTrxs.filter(trx => {
            var isDone = trx.status == 'settlement'
            return isDone && trx.bank.atasnama.toLowerCase().includes(this.pencarian.toLowerCase()) || isDone && trx.nomor.includes(this.pencarian)
            ;
          })

        case 'invalid_evidence':
          return this.$props.realtimeTrxs.filter(trx => {
            var isInvalid = trx.status == 'invalid_evidence'
            return isInvalid && trx.bank.atasnama.toLowerCase().includes(this.pencarian.toLowerCase()) || isInvalid && trx.nomor.includes(this.pencarian)
            ;
          })
      
        default:
          return []
      }
    },
  },
  firestore () {
    return {
      user: db.collection('users').doc(firebase.auth().currentUser.uid)
    }
  }
}
</script>