<template>
  <div class="d-flex flex-column justify-content-center vh-100 text-center">
    <button class="btn btn-primary" @click="generateListNumber()">Generate Numbers</button>
    <!-- <button @click="checkGoib()">check goib</button> -->
    <!-- <button class="btn btn-primary m-auto" style="width:200px" @click="fixCS5()">CS To CS5</button> -->
    <!-- {{exists}} -->
  </div>
</template>

<script>
import {db} from '@/main'
import moment from 'moment'
//import numbersChecker from './data/numbers.js'
//import numbersChecker from './data/numbers2526.js'
//import logs from './data/logsTrx.js'
//import logs from './data/log24.js'
//import logs from './data/log26.js'
//import logs from './data/logs25'
//import logs from './data/logsMar'
import codes from './data/codes.js'

var numbersChecker = [
  // {shortcut: 'TL1564', provider: 'TELKOMSEL', value: '082332288854', nominal: 0, order: 1597, identifier: 'log_02_Sep_2024'},
]
var logs = []



export default({
  data(){
    return {
      //deleting logs
      totalLogs: 0,
      deletedLogs: 0,
      skipped: 0,

      codes: codes,
      logss: logs,
      savedData: 0,
      redundantLogs: 0,
      deleteLogs: 0,
      redundantData: 0,
      savedLogs: 0,
      numberChecked: numbersChecker,
      monthyear: moment().format('MMM_yyyy'),
    }
  },
  created(){
    // db.collection('cs_logs').where('identifier', '==', 'log_22_Jul_2021').where('nominal', '==', 1030000).get().then((snaos)=> {
    //   snaos.docs.forEach((snp) => {
    //     console.log(snp.data())
    //   })
    // })
  },
  methods: {
    async fixCS5(){
      const ref = await db.collection('cs_logs').where('shift', '==', 'siang').get()
      ref.docs.forEach(async (doc) => {
        await db.collection('cs_logs').doc(doc.id).update({'shift':'limasiang'})
        console.log(`updated ${doc.id}`)
      })
    },
    updateOrders(){
      var data  = 0
      db.collection('numbers').where('identifier', '==', 'log_09_Jul_2021').get().then((snaps) => {
        snaps.docs.forEach(element => {
          if(element.data().shortcut.substring(0, 2) == 'TA'){
            data ++;
            console.log(element.data().order)
/*
            db.collection('numbers').doc(element.id).update({
              order: element.data().order - 97 + 200
            })*/
          }
        });
      }).then(() => {
        console.log(`done: ${data}`)
      })
    },
    formattgl(val){
      return moment(val).format('HH:mm:ss - DD MMMM yyyy')
    },
    deleteRedundant(){
      db.collection('numbers').where('identifier', '==', 'log_29_Mar_2021').get().then((snaps) => {
        snaps.docs.forEach((data) => {
          this.totalLogs++
          db.collection('numbers').doc(data.id).delete().then(() => { this.deletedLogs++ })
        })
      })
    },
    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    generateLogs(){
      this.logss.forEach(element => {
        if(element.nominal != 0){

          var ref = db.collection('cs_logs').doc();
          ref.set({
            id: ref.id,
            month: 'Apr_2021',
            identifier: element.identifier,
            nominal: element.nominal,
            nomor: element.nomor,
            shift: element.shift,
            cs: element.cs
          }).then(() => { this.savedLogs ++ })
          
        } else {
          this.redundantLogs ++
        }
      });
    },
    generateListNumber(){
      this.numberChecked.forEach(el => {
        let iseXsit = false
        this.exists.forEach((ite) => {
          if(el.shortcut == ite.shortcut) iseXsit = true
        })
        console.log(iseXsit)
        if(!iseXsit){
          var refNum = db.collection('numbers').doc();
          refNum.set({
            id: refNum.id,
            shortcut: el.shortcut,
            provider: el.provider,
            value: el.value,
            nominal: el.nominal,
            order: el.order,
            identifier: el.identifier,
            created_at: new Date(),
            updated_at: new Date()
          }).then(() => {this.savedData++; console.log(this.savedData)})
        }
      })
    },
    checkGoib(){
      var identifier = 'log_31_Mar_2022'
      this.codes.forEach((code) => {
        db.collection('numbers').where('identifier', '==', identifier).where('shortcut', '==', code).get().then((snap) => {
          if(snap.docs.length > 1){
            snap.docs.slice(1).forEach(async (snap) => {
              await db.collection('numbers').doc(snap.id).delete()
              console.log(snap.id)
            })
          }
        })
      })
      // db.collection('converts').where('nominal', '==', 130000).where('provider', '==', 'XL/AXIS').get().then((snaps) => {
      //   snaps.docs.forEach((snap) => {
      //     if(snap.data().cs == 'cs3@bypulsa.com' && snap.data().admin == 'Ridho' && snap.data().media == 'APP'){
      //       console.log(snap.data())
      //     }
      //   })
      // })
    }
  },
  firestore(){
    return {
      exists: db.collection('numbers').where('identifier', '==', 'log_07_Jan_2023')
    }
  }
})
</script>