import moment from 'moment'
import firebase from 'firebase/app'
import 'firebase/auth'
import {db} from '@/main'

const firstMixin = {
    methods: {
        log(val){
          console.log(val)
        },
        logOut(){
          firebase.auth().signOut().then(() => {
            this.$router.replace('login')
          })
        },
        currency(value){
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        fbDateToString(value, format){
          return moment(value).format(format)
        },
        timeDiff(value){
          if (value) {
            return moment(String(value)).fromNow()
          }
        },
        isMoreThanADay(value){
          return (moment.now() - value)/3600000 < 24
        },
        titleCase(str) {
          var splitStr = str.toLowerCase().split(' ');
          for (var i = 0; i < splitStr.length; i++) {
              splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
          }
          return splitStr.join(' '); 
        },
        getProvider(nomor){
          var validated = nomor.replace(/-/g, "");
          switch(validated.substring(0,4)){
            case '0811':
            case '0812': 
            case '0813':
            case '0821':
            case '0822':
            case '0823':
            case '0851':
            case '0852':
            case '0853':
              return 'TELKOMSEL';
            case '0817':
            case '0818':
            case '0819':
            case '0859':
            case '0877':
            case '0878':
            case '0831':
            case '0832':
            case '0838':
              return 'AXIS';
            case '0814': 
            case '0815':
            case '0816':
            case '0855':
            case '0856':
            case '0857':
            case '0858':
              return 'INDOSAT';
            case '0895':
            case '0896': 
            case '0897':
            case '0898':
            case '0899':
              return 'THREE';
            case '0881':
            case '0882':
            case '0883':
            case '0884':
            case '0885':
            case '0886':
            case '0887':
            case '0888':
            case '0889':
              return 'SMARTFREN';
            default:
              return 'SALAH';
          }
        },
        bankIconURL(name){
          const resp = this.Bank(name)
          return resp.iconURL
        },
        getProviderIcon(string){
          if(string != null){
            switch(string.toUpperCase()){
              case 'TELKOMSEL':
                return require('@/assets/img/provider/telkomsel-logo.png')
              case 'AXIS': case 'XL': case 'XL/AXIS':
                return require('@/assets/img/provider/xl-logo.png')
              case 'THREE':
                return require('@/assets/img/provider/three-logo.png')
              case 'INDOSAT':
                return require('@/assets/img/provider/indosat-logo.png')
              case 'SMARTFREN':
                return require('@/assets/img/provider/smartfren-logo.png')
            }
          } else {
            return ''
          }
        },
        getBankFee(bank) {
          switch(bank.toUpperCase()) {
            case 'BCA': case 'BNI': case 'BRI': case 'BTN': case 'DANA': case 'SHOPEEPAY': case 'MANDIRI': case 'SHOPPEEPAY': case 'SHOPEPAY': case 'SAKUKU': case 'ISAKU': case 'OVO':
              return 0
    
            case 'GOPAY': case 'LINKAJA':
              return 1000
            
            default:
              return 4000
          }
        },
        eWalletCode(ewallet){
          switch (ewallet.toUpperCase()) {
            case 'OVO': return '39358'
            case 'SHOPEEPAY': return '122'
            case 'DANA': return '3901'
            case 'GOPAY': return '70001'
            case 'LINKAJA': return '09110'
          }
        },
        check() {
          var now = moment();
          var hourToCheck = (now.day() !== 0)?17:15;
          var dateToCheck = now.hour(hourToCheck).minute(30);
          
          return moment().isAfter(dateToCheck);
        },
        expiredChecker(trxs){
          var end = moment()
          var start = moment().subtract(moment.duration(48, 'hours'))

          trxs.forEach((trx) => {
            if(trx.status == 'user_confirmation' && trx.expired == null){
              var c = trx.updated_at.seconds*1000
              var time = moment(c)
              var isNotExpired = time.isBetween(start, end)
              if(!isNotExpired){
                db.collection('converts').doc(trx.id).update({
                  'expired': true
                })
              }
            }
          })
        },
    },
}

export default firstMixin