<template>
<div>
    <table>
        <thead>
            <tr>
                <th colspan="4"></th>
                <th :colspan="teamList.length">CHIPS: {{numberYesterday.length}} ---- TOTAL PULSA MASUK: {{getSumPerProvider()}}</th>
                <th colspan="4"></th>
            </tr>
        </thead>
        <thead>
            <tr>
                <th>ID</th>
                <th>Nomor</th>
                <th>
                    <div>Saldo</div>
                    <div class="text-small">{{currency(sumSaldoAwal)}}</div>
                </th>
                <th v-for="(team, idx) in teamList" :key="'title_three_'+idx">
                    <div>{{team.name}}</div>
                    <div class="text-small">{{getSumPerCS(team.identifier)}}</div>
                </th>
                <th>Sell</th>
                <th>Est</th>
                <th>
                    <div>Checker</div>
                    <div class="text-small">{{currency(sumSaldoChecker)}}</div>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(tr, index) in numberYesterday" :key='index' :class="{'checkedValue': isMoreThanADay(new Date(tr.updated_at.seconds * 1000)) && getChecker(tr) == getSingleSum(tr), 'bg-danger text-white': !isNaN(getChecker(tr)) && getChecker(tr) != getSingleSum(tr)}">
                <td class="align-middle" style="width:36px" @click="printData(tr)">
                    {{tr.shortcut}}
                </td>
                <td class="align-middle" style="width:130px">
                    <div>{{tr.value}}</div>
                </td>
                <td class="align-middle text-center" :class="{'bg-warning text-white font-weight-bold' : tr.nominal >= 1050000}">
                    {{currency(tr.nominal)}}
                </td>
                <td class="hrvable text-center" v-for="(team, idx) in teamList" :key="'three_'+idx" @dblclick="showLogTrxs($store.state.seller.logTime, tr, team.identifier, getTheNomorSum(tr, team.identifier))">{{getTheNomorSum(tr, team.identifier)}}</td>
                <td>{{getTheNomorSum(tr, 'sell')}}</td>
                <td>{{currency(getSingleSum(tr))}}</td>
                <td>
                    <div v-if="getChecker(tr) != null">{{currency(getChecker(tr))}}</div>
                    <div v-else>-</div>
                    <div class="small">{{currency(getChecker(tr) - getSingleSum(tr))}}</div>
                </td>
                <td class="align-middle" style="max-width:84px">
                    <input v-if="!saving && editValue == tr.id" style="width:100px" type="text" v-model.number="newAmount" @keypress.enter="saveData(tr)" @keyup.esc="editValue = ''">
                    <div v-else-if="saving" class="align-middle">
                    <orbit-spinner
                        :animation-duration="1200"
                        :size="36"
                        color="#ff1d5e"
                        style="margin:auto"
                    />
                    </div>
                    <div v-else class="d-flex justify-content-between">
                    <button v-if="getChecker(tr) == null" class="btn btn-sm btn-success" @click="validity(tr, getSingleSum(tr))"><font-awesome-icon icon="check" /></button>
                    <button class="btn btn-sm btn-primary" @click="editValue = tr.id"><font-awesome-icon icon="edit" /></button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="position-fixed" :style="menuStyle('arrow')" @click="showSideMenu">
        <button class="btn btn-sm btn-danger" v-if="left == 0"><font-awesome-icon icon="arrow-left" /></button>
        <button class="btn btn-sm btn-success" v-else><font-awesome-icon icon="arrow-right" /></button>
</div>
<transition name="fade">
    <div class="position-fixed" :style="menuStyle('card')">
        <div class="p-4">
            <button v-if="!checkAllConfirm" class="btn btn-outline-primary" @click="checkAllConfirm = !checkAllConfirm">Check Semua</button>
            <div v-else class="d-flex justify-content-between">
                <button class="btn btn-outline-secondary" @click="checkAllConfirm = !checkAllConfirm">Tidak</button>
                <button class="btn btn-success" @click="checkAll()">YES!</button>
            </div>
        </div>
    </div>
</transition>
</div>
</template>

<script>
import moment from 'moment'
import {db} from '../../main'
import { OrbitSpinner } from 'epic-spinners'
const teamList = [
  {
    'name': '1A',
    'identifier': 'satupagi'
  },
  {
    'name': '1B',
    'identifier': 'satusiang'
  },
  {
    'name': '2A',
    'identifier': 'duapagi'
  },
  {
    'name': '2B',
    'identifier': 'duasiang'
  },
  {
    'name': '3A',
    'identifier': 'tigapagi'
  },
  {
    'name': '3B',
    'identifier': 'tigasiang'
  },
  {
    'name': '4A',
    'identifier': 'empatpagi'
  },
  {
    'name': '4B',
    'identifier': 'empatsiang'
  },
  {
    'name': '5A',
    'identifier': 'limapagi'
  },
  {
    'name': '5B',
    'identifier': 'limasiang'
  }
]
export default {
    components: {
        OrbitSpinner
    },
    data: () => {
        return {
            left:-200,
            checkAllConfirm: false,
            teamList: teamList,
            sumSaldoAwal: 0,
            sumSaldoChecker: 0,
            loading: false,
            newAmount: null,
            saving: false,
            editValue: '',
            numberYesterday: [],
            numberToday: [],
            csLogs: []
        }
    },
    props: {
        provider: String
    },
    mounted(){
        this.viewData()
    },
    created() {
        this.unsubscribe = this.$store.subscribe((action) => {
            if (action.type === 'changeChipsDate') {
                this.viewData()
            }
        });
    },
    beforeDestroy: function() {
        this.unsubscribe();
    },
    methods: {
        printData(val){console.log(val)},
        menuStyle(type){
            switch (type) {
                case 'card':
                    return 'left: '+this.left+'px;top: 256px;width: 200px;background: white;box-shadow: rgb(0 0 0 / 50%) 0px 0px 8px 0px;'
                case 'arrow':
                    return 'left:'+(200+this.left)+'px;top:256px'
            }
        },
        showSideMenu(){
            if(this.left == 0){ this.left -=200 } else { this.left = 0 }
        },
        async checkAll(){
            var rekaped = 0
            this.numberYesterday.forEach(yest => {
                const found = this.numberToday.some(today => today.value === yest.value)
                if(!found){
                    this.validity(yest, this.getSingleSum(yest))
                    rekaped++
                }
            });
            console.log(`terekap: ${rekaped}`)
        },
        async viewData(){
            this.loading = true
            this.numberYesterday = []
            this.numberToday = []
            this.csLogs = []
            this.sumSaldoAwal = 0
            this.sumSaldoChecker = 0

            if(this.$store.state.seller.listChipsDate != null){
                var yesterdayData = new Date(new Date(this.$store.state.seller.listChipsDate).setDate(this.$store.state.seller.listChipsDate.getDate() - 1))
                this.$store.state.seller.logTime = 'log_'+moment(this.$store.state.seller.listChipsDate).format('DD_MMM_yyyy')
                this.$store.state.seller.logYesterday = 'log_'+moment(yesterdayData).format('DD_MMM_yyyy')

                await db.collection('numbers').where('identifier', '==', this.$store.state.seller.logYesterday).where('provider', '==', this.$props.provider).orderBy('order').get().then((docs) => {
                docs.docs.forEach(el => {
                    this.numberYesterday.push(el.data())
                    this.sumSaldoAwal += el.data().nominal
                });
                })

                await db.collection('numbers').where('identifier', '==', this.$store.state.seller.logTime).where('provider', '==', this.$props.provider).orderBy('order').get().then((docs) => {
                docs.docs.forEach(el => {
                    this.numberToday.push(el.data())
                    this.sumSaldoChecker += el.data().nominal
                });
                })

                await db.collection('cs_logs').where('identifier', '==', this.$store.state.seller.logTime).get().then((docs) => {
                docs.docs.forEach(el => {
                    this.csLogs.push(el.data())
                });
                })

                //this.getNumbers()

                this.loading = false
            }
        },
        getChecker(val){
        var data
        this.numberToday.forEach(el => {
            if(val.value == el.value){
            data = el.nominal
            }
        })

        return data;
        },
        getSingleSum(val){
        var data = []
        var sumUp = 0
        this.csLogs.forEach(element => {
            if(element.nomor == val.value && element.identifier == this.$store.state.seller.logTime){
            data.push(element)
            }
        });

        data.forEach(element => {
            if(element.shift.toUpperCase() != 'SELL'){
            sumUp += element.nominal
            } else {
            sumUp -= element.nominal
            }
        });

        return (sumUp + val.nominal);
        },
        getSumPerCS(shift){
            let sum = 0
            const provider = this.$props.provider
            for (const log of this.csLogs) {
                if(log.shift.toUpperCase() == shift.toUpperCase() && this.getProvider(log.nomor) == provider) sum+=log.nominal
            }
            return this.currency(sum)
        },
        getSumPerProvider(){
            let sum = 0
            const provider = this.$props.provider
            for (const log of this.csLogs) {
                if(this.getProvider(log.nomor) == provider && log.shift !== 'SELL') sum+=log.nominal
            }
            return this.currency(sum)
        },
        getTheNomorSum(val, shift){
        var data
        this.csLogs.forEach(element => {
            if(element.nomor == val.value && element.identifier == this.$store.state.seller.logTime && element.shift.toUpperCase() == shift.toUpperCase()){
            data = this.currency(element.nominal)
            }
        });

        return data;
        },
        saveData(data){
        this.saving = true
        db.collection('numbers').where('identifier', '==', this.$store.state.seller.logTime).where('value', '==', data.value).get().then((docs) => {
            if(docs.docs.length > 0){
                db.collection('numbers').doc(docs.docs[0].data().id).update({
                    nominal: this.newAmount,
                    updated_at: new Date()
                }).then(() => {
                    this.numberToday.forEach((element, index) => {
                    if(element.id == docs.docs[0].data().id){
                        this.numberToday[index].nominal = this.newAmount
                    }
                    });
                    this.editValue = ''
                    this.saving = false
                    this.newAmount = null
                })
            } else {
                var refNum = db.collection('numbers').doc();
                refNum.set({
                    id: refNum.id,
                    shortcut: data.shortcut,
                    provider: data.provider,
                    value: data.value,
                    nominal: this.newAmount,
                    order: data.order,
                    identifier: this.$store.state.seller.logTime,
                    created_at: new Date(),
                    updated_at: new Date()
                }).then(() => {
                    this.numberToday.push({
                        id: refNum.id,
                        shortcut: data.shortcut,
                        provider: data.provider,
                        value: data.value,
                        nominal: this.newAmount,
                        order: data.order,
                        identifier: this.$store.state.seller.logTime,
                        created_at: {seconds: new Date().getTime()/1000},
                        updated_at: {seconds: new Date().getTime()/1000}
                    })
                    this.editValue = ''
                    this.saving = false
                    this.newAmount = null
                })
            }
        })
        },
        validity(data, nominal){
            var cimi = ''
            if(data.cimi != null) cimi = data.cimi
            var refNum = db.collection('numbers').doc();
            refNum.set({
                id: refNum.id,
                shortcut: data.shortcut,
                provider: data.provider,
                value: data.value,
                nominal: nominal,
                order: data.order,
                identifier: this.$store.state.seller.logTime,
                created_at: new Date(),
                updated_at: new Date(),
                cimi: cimi
            }).then(() => {
                this.numberToday.push({
                id: refNum.id,
                shortcut: data.shortcut,
                provider: data.provider,
                value: data.value,
                nominal: nominal,
                order: data.order,
                cimi: cimi,
                identifier: this.$store.state.seller.logTime,
                created_at: {seconds: new Date().getTime()/1000},
                updated_at: {seconds: new Date().getTime()/1000}
                })
                this.editValue = ''
                this.newAmount = null
            })
        },
    }
}
</script>